
import { ROOT_ACTIONS } from '@/store/actions';
import { Component, VModel, Vue } from 'vue-property-decorator';

import * as ApiServices from '@/services/ApiServices';

@Component
export default class CreateApiKey extends Vue {
  @VModel({ required: true }) show!: boolean;

  loading = false;

  inputs = {
    name: '',
    limit: 100
  }

  async submit() {
    try {
      this.loading = true;
      await ApiServices.CreateApiKey({
        limit: this.inputs.limit,
        name: this.inputs.name
      })
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Sleutel aangemaakt'
      })
      this.$emit('refresh')
      this.show = false;
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading = false;
    }
  }
} 
